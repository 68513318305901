import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ATVResponse, ChatrPaymentResponse } from '../PaymentResponse/ChatrPaymentResponse';
import { UserserviceService } from '../CCPaymentServices/CCPayService';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  private responsemessage: string;
  public ChatrPaymentResponse: ChatrPaymentResponse;
  public order_status: string;
  public bank_ref_no: string;
  public amount?: number;
  public SIMRegisterID: number;
  public PaymentID: string;
  public SIMNo: string;
  public billing_name: string;

  constructor(public route: ActivatedRoute, public UserserviceService: UserserviceService) {
    this.route.queryParams.subscribe((param) => {
    this.order_status = param.order_status;
     this.bank_ref_no  = param.bank_ref_no;
     this.amount = param.amount;
     this.SIMRegisterID = param.merchant_param1;
     this.PaymentID = param.tracking_id;
     this.SIMNo = param.merchant_param5;
     this.billing_name = param.billing_name;
    });
  }
  ngOnInit() {
    this.ChatrPaymentResponse = new ChatrPaymentResponse();
    this.route.queryParams.subscribe((param) => {
      this.order_status = param.order_status;
      this.bank_ref_no  = param.bank_ref_no;
      this.amount = param.amount;
      this.SIMRegisterID = param.merchant_param1;
      this.PaymentID = param.tracking_id;
      this.SIMNo = param.merchant_param5;
      this.billing_name = param.billing_name;
    });
    this.GetATVVoucherDetails(this.SIMNo);
  }

  backToHomePage() {
  }

  GetATVVoucherDetails(SIMRegistration: any) {
    this.ChatrPaymentResponse = new ChatrPaymentResponse();
    this.UserserviceService.GetPaymentAndVoucher(SIMRegistration).subscribe((result: any) => {
      this.ChatrPaymentResponse = result;
    });
  }

}
