<div class="container-fluid">
    <div>
        <h1> <b> Terms and conditions of use</b></h1>
    </div>
    <div>
        <h3> <b>
                Introduction
            </b>
        </h3>
    </div>
    <div>
        <ul>
            <li> <b>1. </b> These terms and conditions shall govern your use of our website.</li>
            <li> <b>1.1.</b> By using our website, you accept these terms and conditions in full; accordingly, if you
                disagree with these terms and conditions or any part of these terms and conditions, you must not use our
                website.</li>
            <li> <b>1.2.</b> If you [register with our website, submit any material to our website or use any of our
                website services], we will ask you to expressly agree to these terms and conditions.</li>
            <li> <b>1.3.</b> You must be at least [18] years of age to use our website; by using our website or agreeing
                to these terms and conditions, you warrant and represent to us that you are at least [18] years of age.
            </li>
            <li> <b> 1.4.</b> Our website uses cookies; by using our website or agreeing to these terms and conditions,
                you consent to our use of cookies in accordance with the terms of our [privacy and cookies policy].</li>
        </ul>
    </div>

    <div>
        <ul>
            <li>
                <b> 2. Licence to use website</b>
            </li>

            <li> </li>
            <li>2.1. You may: </li>
            <li>(a) view pages from our website in a web browser; </li>
            <li>(b) download pages from our website for caching in a web browser; </li>
            <li>(c) print pages from our website; </li>
            <li>(d) use [our website services] by means of a web browser], </li>
            <li>subject to the other provisions of these terms and conditions. </li>
            <li>2.2. Except as expressly permitted BY THE provisions of these terms and conditions, you must not
                download any
                material from our website or save any such material to your computer. </li>
            <li>2.3. You may only use our website for [your own personal and business purposes], and you must not use
                our website
                for any other purposes. </li>
            <li>2.4. Except as expressly permitted by these terms and conditions, you must not edit or otherwise modify
                any material
                on our website.</li>
            <li>2.5. Unless you own or control the relevant rights in the material, you must not:</li>
            <li>(a) republish material from our website (including republication on another website); </li>
            <li>(b) sell, rent or sub-license material from our website; </li>
            <li>(c) show any material from our website in public; </li>
            <li>(d) exploit material from our website for a commercial purpose; or </li>
            <li>(e) redistribute material from our website. </li>
            <li>2.6. We reserve the right to restrict access to areas of our website, or indeed our whole website, at
                our
                discretion; you must not circumvent or bypass, or attempt to circumvent or bypass, any access
                restriction measures
                on our website.</li>

        </ul>
    </div>




    3. Acceptable use
    3.1. You must not:
    (a) use our website in any way or take any action that causes, or may cause, damage to the website or impairment of
    the performance, availability or accessibility of the website;
    (b) use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful,
    illegal, fraudulent or harmful purpose or activity;
    (c) use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of
    (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious
    computer software;
    (d) conduct any systematic or automated data collection activities (including without limitation scraping, data
    mining, data extraction and data harvesting) on or in relation to our website without our express written consent;
    (e) access or otherwise interact with our website using any robot, spider or other automated means, except for the
    purpose of search engine indexing;
    (f) violate the directives set out in the robots.txt file for our website; or
    (g) use data collected from our website for any direct marketing activity (including without limitation email
    marketing, SMS marketing, telemarketing and direct mailing).
    3.2. You must not use data collected from our website to contact individuals, companies or other persons or
    entities.
    3.3. You must ensure that all the information you supply to us through our website, or in relation to our website,
    is true, accurate, current, complete and non-misleading.
    4. Registration and accounts
    4.1. You may register for an account with our website by [completing and submitting the account registration form on
    our website, and clicking on the verification link in the email that the website will send to you].
    4.2. You must not allow any other person to use your account to access the website.
    4.3. You must notify us in writing immediately if you become aware of any unauthorised use of your account.
    4.4. You must not use any other person's account to access the website[, unless you have that person's express
    permission to do so].
    5. User login details
    5.1. If you register for an account with our website, [we will provide you with] OR [you will be asked to choose] [a
    user ID and password].
    5.2. Your user ID must not be liable to mislead; you must not use your account or user ID for or in connection with
    the impersonation of any person.
    5.3. You must keep your password confidential.
    5.4. You must notify us in writing immediately if you become aware of any disclosure of your password.
    5.5. You are responsible for any activity on our website arising out of any failure to keep your password
    confidential, and may be held liable for any losses arising out of such a failure.
    6. Cancellation and suspension of account
    6.1. We may:
    (a) suspend your account;
    (b) cancel your account; and/or
    (c) edit your account details,
    at any time in our sole discretion without notice or explanation.
    6.2. You may cancel your account on our website [using your account control panel on the website].
    7. Your content: licence
    7.1. In these terms and conditions, "your content" means all works and materials (including without limitation text,
    graphics, images, audio material, video material, audio-visual material, scripts, software and files) that you
    submit to us or our website for storage or publication on, processing by, or transmission via, our website.
    7.2. You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, reproduce, store, adapt,
    publish, translate and distribute your content in any existing or future media OR reproduce, store and publish your
    content on and in relation to this website and any successor website OR reproduce, store and, with your specific
    consent, publish your content on and in relation to this website.
    7.3. You hereby waive all your moral rights in your content to the maximum extent permitted by applicable law; and
    you warrant and represent that all other moral rights in your content have been waived to the maximum extent
    permitted by applicable law.
    7.4. You may edit your content to the extent permitted using the editing functionality made available on our
    website.
    7.5. Without prejudice to our other rights under these terms and conditions, if you breach any provision of these
    terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in
    any way, we may delete, unpublish or edit any or all of your content.
    8. Your content: rules
    8.1. You warrant and represent that your content will comply with these terms and conditions.
    8.2. Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be
    capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable
    law).
    8.3. Your content, and the use of your content by us in accordance with these terms and conditions, must not:
    (a) be libellous or maliciously false;
    (b) be obscene or indecent;
    (c) infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or
    other intellectual property right;
    (d) infringe any right of confidence, right of privacy or right under data protection legislation;
    (e) constitute negligent advice or contain any negligent statement;
    (f) constitute an incitement to commit a crime, instructions for the commission of a crime or the promotion of
    criminal activity;
    (g) be in contempt of any court, or in breach of any court order;
    (h) be in breach of racial or religious hatred or discrimination legislation;
    (i) be blasphemous;
    (j) be in breach of official secrets legislation;
    (k) be in breach of any contractual obligation owed to any person;
    (l) depict violence in an explicit, graphic or gratuitous manner;
    (m) be pornographic, lewd, suggestive or sexually explicit;
    (n) be untrue, false, inaccurate or misleading;
    (o) consist of or contain any instructions, advice or other information which may be acted upon and could, if acted
    upon, cause illness, injury or death, or any other loss or damage;
    (p) constitute spam;
    (q) be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful,
    discriminatory or inflammatory; or
    (r) cause annoyance, inconvenience or needless anxiety to any person.

    9. Limited warranties
    9.1. We do not warrant or represent:
    (a) the completeness or accuracy of the information published on our website;
    (b) that the material on the website is up to date; or
    (c) that the website or any service on the website will remain available.
    9.2. We reserve the right to discontinue or alter any or all of our website services, and to stop publishing our
    website, at any time in our sole discretion without notice or explanation; and save to the extent expressly provided
    otherwise in these terms and conditions, you will not be entitled to any compensation or other payment upon the
    discontinuance or alteration of any website services, or if we stop publishing the website.
    9.3. To the maximum extent permitted by applicable law, we exclude all representations and warranties relating to
    the subject matter of these terms and conditions, our website and the use of our website.
    10. Breaches of these terms and conditions
    10.1. Without prejudice to our other rights under these terms and conditions, if you breach these terms and
    conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we
    may:
    (a) send you one or more formal warnings;
    (b) temporarily suspend your access to our website;
    (c) permanently prohibit you from accessing our website;
    (d) block computers using your IP address from accessing our website;
    (e) contact any or all of your internet service providers and request that they block your access to our website;
    (f) commence legal action against you, whether for breach of contract or otherwise; and/or
    (g) suspend or delete your account on our website.

    10.2. Where we suspend or prohibit or block your access to our website or a part of our website, you must not take
    any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or
    using a different account).
    11. Variation
    11.1. We may revise these terms and conditions from time to time.
    11.2. The revised terms and conditions shall apply to the use of our website from the date of publication of the
    revised terms and conditions on the website, and you hereby waive any right you may otherwise have to be notified
    of, or to consent to, revisions of these terms and conditions. OR We will give you written notice of any revision of
    these terms and conditions, and the revised terms and conditions will apply to the use of our website from the date
    that we give you such notice; if you do not agree to the revised terms and conditions, you must stop using our
    website.
    11.3. If you have given your express agreement to these terms and conditions, we will ask for your express agreement
    to any revision of these terms and conditions; and if you do not give your express agreement to the revised terms
    and conditions within such period as we may specify, we will disable or delete your account on the website, and you
    must stop using the website.
    12. Assignment
    12.1. You hereby agree that we may assign, transfer, sub-contract or otherwise deal with our rights and/or
    obligations under these terms and conditions.
    12.2. You may not without our prior written consent assign, transfer, sub-contract or otherwise deal with any of
    your rights and/or obligations under these terms and conditions.
    13. Severability
    13.1. If a provision of these terms and conditions is determined by any court or other competent authority to be
    unlawful and/or unenforceable, the other provisions will continue in effect.
    13.2. If any unlawful and/or unenforceable provision of these terms and conditions would be lawful or enforceable if
    part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in
    effect.
    14. Third party rights
    14.1. A contract under these terms and conditions is for our benefit and your benefit, and is not intended to
    benefit or be enforceable by any third party.
    14.2. The exercise of the parties' rights under a contract under these terms and conditions is not subject to the
    consent of any third party.
    15. Entire agreement
    15.1. The terms and conditions[, together with our privacy and cookies policy, shall constitute the entire agreement
    between you and us in relation to your use of our website and shall supersede all previous agreements between you
    and us in relation to your use of our website.
    16. Law and jurisdiction
    16.1. These terms and conditions shall be governed by and construed in accordance with Indian Law.
    16.2. Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the
    courts of India.
    17. Our details.
    17.1. This website is owned and operated by Uniconnect SIM Pvt. Ltd.
    17.2. We are registered in India under registration number [number], and our registered office is at 604 Midas
    Chamber, Link Road, Andheri W 400053 India.
    17.3. Our principal place of business is at above address.
    17.4. You can contact us on info@myCanadaSIM.com;



</div>