<div class="container-fluid">
    <div>
        <h1> <b> Return & Refund Policy</b></h1>
    </div>
    <ul>
        <li> 1. It is mandatory for the customer to have had reported the issue to our Customer Care Department whilst
            they were overseas. </li>
        <li> 2. If the customer does not cooperate with the Customer Care Department to get the issue resolved, no
            refunds will be given. </li>
        <li> 3. Refund will only be made should the Customer Care Department be unable to get the customer’s SIM card
            functioning. </li>

    </ul>
</div>