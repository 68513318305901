<div class="container">
  <form #SIMRegistration="ngForm" id="SIMRegistration" novalidate>
    <div class="row">
      <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">
        <div class="row">
          <div class="secondheaderNew innerheader text-center">
            <marquee bgcolor="white">Pay for one month and get second month free*</marquee>
          </div>
          <div class="col-md-10 offset-md-2">
            <!-- <h4 class="text-center innerheader my-4">Register your card and save $50 as setup service fee</h4> -->
            <!-- <h4 class="secondheader mb-4 chatr-font">Register your SIM Card</h4> -->
            <h4 class="secondheader mb-4">Register your SIM Card</h4>
            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <label for="FirstName" class="col-form-label textbold">First Name<sup
                    class="text-danger">*</sup></label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control first-letter" id="FirstName" aria-label="First name" required
                  [(ngModel)]="SIMRegistrationModel.FirstName" #FirstName="ngModel" name="FirstName">
                <div *ngIf="!FirstName?.valid && (FirstName?.dirty || FirstName?.touched)">
                  First Name
                </div>
              </div>
            </div>
            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <label for="LastName" class="col-form-label textbold">Last Name<sup class="text-danger">*</sup></label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control first-letter" id="LastName" aria-label="Last name"
                  [(ngModel)]="SIMRegistrationModel.LastName" required name="LastName" #LastName="ngModel">

                <div *ngIf="!LastName?.valid && (LastName?.dirty || LastName?.touched)">
                  Last Name
                </div>
              </div>
            </div>
            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <label for="mobile" class="col-form-label textbold">Indian Mobile Number<sup
                    class="text-danger">*</sup></label>
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-4">
                    <input type="text" value="+91" class="form-control" disabled>
                  </div>
                  <div class="col-8">
                    <input type="text" class="form-control" id="Indianmobilenumber" aria-label="mobile"
                      [(ngModel)]="SIMRegistrationModel.Indianmobilenumber" required name="Indianmobilenumber"
                      #Indianmobilenumber="ngModel" (keypress)="ValidateMobilenumber($event)" minlength=10 maxlength=10>
                    <div
                      *ngIf="!Indianmobilenumber?.valid && (Indianmobilenumber?.dirty || Indianmobilenumber?.touched)">
                      Mobile Number
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <label for="email" class="col-form-label textbold">Email ID<sup class="text-danger">*</sup></label>
              </div>
              <div class="col-md-7">
                <input type="email" class="form-control" id="EmailID" aria-label="email"
                  [(ngModel)]="SIMRegistrationModel.EmailID" required name="EmailID" #EmailID="ngModel"
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" [email]="true">

                <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                  Email ID
                </div>
              </div>
            </div>
            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <label for="CityReceived" class="col-form-label textbold">City where SIM received<sup
                    class="text-danger">*</sup></label>
              </div>
              <div class="col-md-7">
                <select class="form-select" aria-label="Default select example"
                  [(ngModel)]="SIMRegistrationModel.CityReceived" name="CityReceived" id="CityReceived"
                  #CityReceived="ngModel" (change)='onOptionsSelected($event)'>
                  <option *ngFor="let Item of City" [ngValue]="Item.Name" [selected]="Item.Name === 'Ahmedabad'">
                    {{Item.Name}}
                  </option>
                </select>

                <div *ngIf="!CityReceived?.valid && (CityReceived?.dirty || CityReceived?.touched)">
                  Required
                </div>
              </div>
            </div>

            <div class="row mb-4" *ngIf="IsViewConsent">
              <div class="col-md-12 d-flex justify-content-end">
                <fieldset>
                  <div>

                    <label for="IsConsent" class="labelconsent">
                      <input type="checkbox" id="IsConsent" name="IsConsent" value="coding"
                        [(ngModel)]="SIMRegistrationModel.IsConsent" #IsConsent="ngModel" [ngClass]="{
            'is-invalid': IsConsent?.invalid,
            'valid': !IsConsent?.valid && (IsConsent?.dirty || IsConsent?.touched)
       }">
                      I
                      would like to receive information for banking services in Canada</label>
                  </div>
                </fieldset>
                <div *ngIf="!IsConsent?.valid && (IsConsent?.dirty || IsConsent?.touched)">
                  Required
                </div>
              </div>
            </div>

            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
              </div>
              <div class="col-md-7 text-end">
                <button type="submit" class="btn btn-chatr rounded-bottom" (click)="onSubmit(SIMRegistrationModel)"
                  [disabled]="SIMRegistration.invalid"> &nbsp; Continue &nbsp;</button>
                <!-- &gt; -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>