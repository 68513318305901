import { Component, OnInit, NgModule, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, Form } from '@angular/forms/';
import { ReactiveFormsModule, Validators } from '@angular/forms/';
import { CommonModule } from '@angular/common';
import { SIMRegistration } from '../Models/simregistration';
import { SIMRegistrationSec } from '../Models/simregistration-sec';
import { SIMRegistrationChatr } from '../Models/simregistration-sec';
import { ApplicationServiceService } from '../Services/application-service.service';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SIMRegistrationResponse, UserFiles } from '../Models/SIMRegistrationResponse';
import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import { NetworkList, PlanDescription, PlanList, PlanResponse } from '../Models/PlanDetailResponse';
import { SIMRenewalPlanResponse } from '../Models/SIMRenewalPlanResponse';
import { UserserviceService } from '../CCPaymentServices/CCPayService';
import { CCPayRequest } from '../CCPaymentServices/CCPayRequest';
import { CCPaymentservice } from '../Services/CCPayment-service';
import { CCCryptoService } from '../common/models/ccavutil';
import { PaymentModelRequest } from '../Models/PaymentModelRequest';
import { LoaderserviceService } from '../Services/loaderservice.service';

@Component({
  selector: 'app-sim-card-registration-cont',
  templateUrl: './sim-card-registration-cont.component.html',
  styleUrls: ['./sim-card-registration-cont.component.scss']
})
export class SImCardRegistrationContComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationSecModel: SIMRegistrationChatr;
  public CCPayRequest: CCPayRequest;
  public CCPayResponse: CCPayRequest;
  public CCPayResponseFromServer: any;
  public SIMRegistration: SIMRegistration;
  public SIMCardStatus: boolean = false;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public SImNo: string;
  public IsValidSIMNumber: boolean = true;
  public DisplayPlanMessage: boolean = false;
  public Startdate: Date;
  public DisplayMandatoryDiv: boolean = false;
  public SIMValidateResponse: SIMValidateResponse;
  public DisplayPlanMessageKoodoo: boolean = false;
  public IsSubmitted: boolean = false;
  public NetworkName: string = "Chatr";
  public workingKey = '6233B66A0374B099600723AB91D5B2D2';	//Put in the 32-Bit key shared by CCAvenues.TESTING 3010
  public accessCode = 'AVNX39II92BT18XNTB';
  public DisplayPlanMessageChatr: boolean = false;
  public DisplayPlanDetailsChatr: boolean = false;
  public SIMRenewalPlanResponse: SIMRenewalPlanResponse[];
  public SIMRenewalPlanRes: SIMRenewalPlanResponse;
  public PaymentModelRequest: PaymentModelRequest;
  public PlanResponse: PlanResponse;
  public AssignedSIMNo: string;
  public isLoading: any = true;
  @ViewChild('form') form: ElementRef;
  encRequestRes: any;

  IsSIMAvtivated: boolean = false;
  IsSIMValid: boolean = false;

  constructor(
    public ApplicationServiceService: ApplicationServiceService,
    public route: ActivatedRoute,
    public Router: Router,
    public UserserviceService: UserserviceService,
    private CCPaymentservice: CCPaymentservice,
    private CCCryptoService: CCCryptoService,
    private LoaderserviceService: LoaderserviceService

  ) {
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
  }

  ngOnInit(): void {
    this.IsSIMValid = false;
    this.accessCode = 'AVNX39II92BT18XNTB';
    this.Startdate = new Date();
    this.SIMRegistrationSecModel = new SIMRegistrationChatr();
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    this.BindDataTotheModelWhenPreviousClick(this.RegistrationID);
    this.GetlatestMessage();
  }

  ValidatetheSIMCard(SIMNumber: string) {
    this.DisplayPlanMessage = false;
    this.DisplayMandatoryDiv = false;
    this.DisplayPlanMessageKoodoo = false;
    this.DisplayMandatoryDiv = false;
    if (SIMNumber.length < 15) {
      return;
    }
    this.ApplicationServiceService.SIMCardValidationByNetwork(SIMNumber, this.NetworkName).subscribe((result: any) => {
      if (result.ResponseMessage === "Valid") {
        this.SIMCardStatus = false;
        this.IsValidSIMNumber = false;
        if (result.Network === "Fido") {

          this.DisplayMandatoryDiv = true;
          this.GetRenewalPlanList(SIMNumber);
        }
        else if (result.Network === "Koodoo") {
          this.DisplayPlanMessageKoodoo = true;
          this.DisplayMandatoryDiv = true;
          this.GetRenewalPlanList(SIMNumber);
        }
        else if (result.Network === "Chatr") {
          this.DisplayPlanMessageChatr = true;
          this.DisplayMandatoryDiv = true;
          this.GetRenewalPlanList(SIMNumber);
        }
      }
      else if (result.ResponseMessage === "InValid") {
        // Validate souldout SIM CArd
        this.ApplicationServiceService.ValidateSouldoutSIMCard(SIMNumber).subscribe((result: any) => {
          this.PlanResponse = result;
          if (this.PlanResponse.SIMNo === SIMNumber) {
            this.SIMCardStatus = false;
            this.IsValidSIMNumber = false;
            if (this.PlanResponse.ResponseMessage === "Fido") {

              this.DisplayMandatoryDiv = true;
              this.GetRenewalPlanList(SIMNumber);
            }
            else if (this.PlanResponse.ResponseMessage === "Koodoo") {
              this.DisplayPlanMessageKoodoo = true;
              this.DisplayMandatoryDiv = true;
              this.GetRenewalPlanList(SIMNumber);
            }
            else if (this.PlanResponse.ResponseMessage === "Chatr") {
              this.DisplayPlanMessageChatr = true;
              this.DisplayMandatoryDiv = true;
              this.GetRenewalPlanList(SIMNumber);
            }

          }
          else {
            this.SIMCardStatus = true;
            this.IsValidSIMNumber = true;
          }
        })
      }
    });
  }

  OnSubmit(SIMRegistrationSecModel: SIMRegistrationChatr) {
    this.IsSubmitted = true;
    if (SIMRegistrationSecModel.DateofTravel == undefined ||
      SIMRegistrationSecModel.DateofTravel == null
    ) {
      alert('Please provide the travel date.!');
      return;
    }
    if (SIMRegistrationSecModel.EmailID == undefined ||
      SIMRegistrationSecModel.EmailID == null ||
      SIMRegistrationSecModel.EmailID == ""
    ) {
      alert('Please provide the email id.!');
      return;
    }

    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );

    this.SIMRegistrationSecModel = new SIMRegistrationChatr();
    this.SIMRegistrationSecModel.PlanID = 0;
    this.SIMRegistrationSecModel.SIMRegistrationID = this.RegistrationID;
    this.SIMRegistrationSecModel.id = 0;
    this.SIMRegistrationSecModel.SImNumber = SIMRegistrationSecModel.SImNumber;
    this.SIMRegistrationSecModel.DateofTravel = SIMRegistrationSecModel.DateofTravel;
    this.SIMRegistrationSecModel.DateofTravelTentative = SIMRegistrationSecModel.DateofTravelTentative;
    this.SIMRegistrationSecModel.EmailID = SIMRegistrationSecModel.EmailID;
    this.ApplicationServiceService.AddSIMRegistrationSec(this.SIMRegistrationSecModel, this.RegistrationID).subscribe((result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(result);

      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);

      // this.Router.navigateByUrl('/SIMCardRegistratioFinal/' + this.SIMRegistrationResponseModel.SIMRegistrationID + '/Final');
      // this.Router.navigate(['/SIMCardRegistrationFinal/' + this.RegistrationID + '/Final']);

    });
  }

  OnBackButton() {
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
    this.Router.navigate(['/SIMCardRegistration/' + this.RegistrationID]);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  };


  NewSaleEntry() {
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
    this.Router.navigate(['/SIMCardRegistration']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  };

  BindDataTotheModelWhenPreviousClick(RegistrationID: string) {
    this.SIMRegistrationSecModel = new SIMRegistrationChatr();
    if (RegistrationID != null && RegistrationID != "") {
      this.ApplicationServiceService.GetSIMRegistrationDetail(RegistrationID).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationSecModel.SIMRegistrationID = this.SIMRegistrationResponseModel.SIMRegistrationID;
        this.SIMRegistrationSecModel.SImNumber = this.SIMRegistrationResponseModel.SIMCardNumberICCID;
        this.SIMRegistrationSecModel.PlanID = this.SIMRegistrationResponseModel.PlanID;
        this.SIMRegistrationSecModel.id = this.SIMRegistrationResponseModel.id;
        this.SIMRegistrationSecModel.City = this.SIMRegistrationResponseModel.City;
        this.SIMRegistrationSecModel.ReceivedCity = this.SIMRegistrationResponseModel.CityReceived;
        this.SIMRegistrationSecModel.FirstName = this.SIMRegistrationResponseModel.FirstName;
        this.SIMRegistrationSecModel.LastName = this.SIMRegistrationResponseModel.LastName;
        this.SIMRegistrationSecModel.ContactNo = this.SIMRegistrationResponseModel.Indianmobilenumber;
        this.AssignedSIMNo = this.SIMRegistrationSecModel.SImNumber;
        if (this.SIMRegistrationSecModel.SImNumber != undefined && this.SIMRegistrationSecModel.SImNumber != null &&
          this.SIMRegistrationSecModel.SImNumber != "") {

          this.ValidatetheSIMCard(this.SIMRegistrationSecModel.SImNumber);
        }
        if (this.SIMRegistrationSecModel.DateofTravelTentative == undefined || this.SIMRegistrationSecModel.DateofTravelTentative == null ||
          this.SIMRegistrationSecModel.SImNumber == "") {
          this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
        }
      });
    }
    else {
      this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    }
  }


  Validatetheinputsimcard(RegistrationID: string, InputSIMNo: any) {
    this.SIMRegistrationSecModel = new SIMRegistrationChatr();
    if (RegistrationID != null && RegistrationID != "") {
      this.ApplicationServiceService.GetSIMRegistrationDetail(RegistrationID).subscribe((result: any) => {
        this.SIMRegistrationResponseModel = JSON.parse(result);
        this.SIMRegistrationSecModel.SIMRegistrationID = this.SIMRegistrationResponseModel.SIMRegistrationID;
        this.SIMRegistrationSecModel.SImNumber = this.SIMRegistrationResponseModel.SIMCardNumberICCID;
        this.SIMRegistrationSecModel.PlanID = this.SIMRegistrationResponseModel.PlanID;
        this.SIMRegistrationSecModel.id = this.SIMRegistrationResponseModel.id;
        this.SIMRegistrationSecModel.City = this.SIMRegistrationResponseModel.City;
        this.SIMRegistrationSecModel.ReceivedCity = this.SIMRegistrationResponseModel.CityReceived;
        this.SIMRegistrationSecModel.FirstName = this.SIMRegistrationResponseModel.FirstName;
        this.SIMRegistrationSecModel.LastName = this.SIMRegistrationResponseModel.LastName;
        this.SIMRegistrationSecModel.ContactNo = this.SIMRegistrationResponseModel.Indianmobilenumber;
        this.AssignedSIMNo = this.SIMRegistrationSecModel.SImNumber;
        if (this.SIMRegistrationSecModel.SImNumber != undefined && this.SIMRegistrationSecModel.SImNumber != null &&
          this.SIMRegistrationSecModel.SImNumber != "") {
          this.ValidatetheSIMCard(this.SIMRegistrationSecModel.SImNumber);
        }
        if (this.SIMRegistrationSecModel.DateofTravelTentative == undefined || this.SIMRegistrationSecModel.DateofTravelTentative == null ||
          this.SIMRegistrationSecModel.SImNumber == "") {
          this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
        }
        if (InputSIMNo != undefined && InputSIMNo != null && InputSIMNo != "") {
          if (this.SIMRegistrationResponseModel.SIMCardNumberICCID != undefined && this.SIMRegistrationResponseModel.SIMCardNumberICCID != "" && this.SIMRegistrationResponseModel.SIMCardNumberICCID != null) {

            if (this.SIMRegistrationResponseModel?.SIMCardNumberICCID != InputSIMNo) {
              alert('Please check the SIM Card No. SIM card number does not registered with the provided contact No.!');
              this.IsSIMValid = true;
              return;
            }
          }
        }
      });
    }
    else {
      this.SIMRegistrationSecModel.DateofTravelTentative = 'Confirmed';
    }
  }


  GetRenewalPlanList(SIMNumber: string) {
    this.SIMRenewalPlanResponse = [];
    this.ApplicationServiceService.GetSIMproductRenewalPlan(SIMNumber).subscribe((result: any) => {
      this.SIMRenewalPlanResponse = result;
    });
  }

  checkout(SIMRegistrationChatr: SIMRegistrationChatr) {
    // Check If SIM Card Present into the Payment table
    if (this.AssignedSIMNo != undefined && this.AssignedSIMNo != null && this.AssignedSIMNo != "") {
      this.ValidateSIMCardActiveationStatus(this.AssignedSIMNo, SIMRegistrationChatr.SImNumber);
    }

    // Update sim card  
    this.Validatetheinputsimcard(SIMRegistrationChatr.SIMRegistrationID, SIMRegistrationChatr.SImNumber);

    this.CCPayResponse = new CCPayRequest();
    this.CCPayResponseFromServer = new CCPayRequest();
    this.SIMRenewalPlanRes = this.GetSelectedPlanDetails(SIMRegistrationChatr.PlanID);
    let PlanId = this.SIMRenewalPlanRes.id;
    let PlanName = this.SIMRenewalPlanRes.PlanName;

    this.CCPayResponse = this.FillCCPayRequestData(SIMRegistrationChatr, this.SIMRenewalPlanRes);
    this.ApplicationServiceService.SIMRegistrationChatrAdd_New(SIMRegistrationChatr).then(_ => {
      this.UserserviceService.PostPaymentRequestData(this.CCPayRequest).subscribe((result: any) => {
        this.CCPayResponseFromServer = result;
      });
    });

    this.waitfunction(1000);
    let redirect_url = 'https://www.mycanadasim.com/payservice/ccavResponseHandler.aspx';
    let redirect_url_cancel = 'https://www.mycanadasim.com/payservice/ccavResponseHandler.aspx';

    let useremail = SIMRegistrationChatr.EmailID;
    this.PaymentModelRequest = new PaymentModelRequest();
    this.PaymentModelRequest.tid = SIMRegistrationChatr.SIMRegistrationID;
    this.PaymentModelRequest.merchant_id = "33038";
    this.PaymentModelRequest.order_id = SIMRegistrationChatr.SIMRegistrationID;
    this.PaymentModelRequest.currency = "INR";
    this.PaymentModelRequest.amount = this.SIMRenewalPlanRes.Price;
    this.PaymentModelRequest.redirect_url = redirect_url;
    this.PaymentModelRequest.cancel_url = redirect_url;
    this.PaymentModelRequest.language = "en";
    this.PaymentModelRequest.billing_name = SIMRegistrationChatr.FirstName;
    this.PaymentModelRequest.billing_address = SIMRegistrationChatr.address;
    this.PaymentModelRequest.billing_city = SIMRegistrationChatr.City;
    this.PaymentModelRequest.billing_state = SIMRegistrationChatr.State;
    this.PaymentModelRequest.billing_zip = SIMRegistrationChatr.PostalCode;
    this.PaymentModelRequest.billing_country = "India";
    this.PaymentModelRequest.billing_tel = SIMRegistrationChatr.ContactNo;
    this.PaymentModelRequest.billing_email = SIMRegistrationChatr.EmailID;
    this.PaymentModelRequest.Merchant_Param1 = SIMRegistrationChatr.SImNumber;
    this.PaymentModelRequest.Merchant_Param2 = PlanId.toString();
    this.PaymentModelRequest.Merchant_Param3 = PlanName;
    this.PaymentModelRequest.Merchant_Param4 = SIMRegistrationChatr.SImNumber;
    this.PaymentModelRequest.Merchant_Param5 = SIMRegistrationChatr.SImNumber;
    this.SIMRegistrationSecModel = SIMRegistrationChatr;

    this.ApplicationServiceService.GetPaymentRequestEncyryptedData(
      this.PaymentModelRequest).subscribe((response: any) => {
        this.encRequestRes = response;
        this.LoaderserviceService.show();
        this.isLoading = true;
        if (!this.IsSIMValid) {
          setTimeout(() => {
            this.isLoading = false;
            this.LoaderserviceService.hide();
            this.form.nativeElement.submit();
          }, 400);
        }
      });
  }

  waitfunction(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }


  // Fill CCPayRequest data 
  FillCCPayRequestData(SIMRegistrationChatr: SIMRegistrationChatr, SIMRenewalPlanRes: SIMRenewalPlanResponse): CCPayRequest {
    this.CCPayRequest = new CCPayRequest();
    this.CCPayRequest.Amount = SIMRenewalPlanRes.Price;
    this.CCPayRequest.ContactNo = SIMRegistrationChatr.ContactNo;
    this.CCPayRequest.CreatedDate = null;
    this.CCPayRequest.Email = SIMRegistrationChatr.EmailID;
    this.CCPayRequest.FirstName = SIMRegistrationChatr.FirstName;
    this.CCPayRequest.LastName = SIMRegistrationChatr.LastName;
    this.CCPayRequest.OrderId = SIMRegistrationChatr.SIMRegistrationID;
    this.CCPayRequest.RegisterID = SIMRegistrationChatr.SIMRegistrationID;
    this.CCPayRequest.SIMNo = SIMRegistrationChatr.SImNumber
    this.CCPayRequest.RequestFromCity = SIMRegistrationChatr.ReceivedCity;
    this.CCPayRequest.address = SIMRegistrationChatr.address;
    this.CCPayRequest.city = SIMRegistrationChatr.City;
    this.CCPayRequest.pincode = SIMRegistrationChatr.PostalCode;
    this.CCPayRequest.state = SIMRegistrationChatr.State;
    this.CCPayRequest.PlanRenewalID = SIMRenewalPlanRes.id;
    this.CCPayRequest.RenewalPlanName = SIMRenewalPlanRes.PlanName;
    return this.CCPayRequest;
  }

  GetSelectedPlanDetails(PlanId: number): SIMRenewalPlanResponse {
    this.SIMRenewalPlanRes = new SIMRenewalPlanResponse();
    this.SIMRenewalPlanRes = this.SIMRenewalPlanResponse.find((obj: any) => obj.id === PlanId);
    return this.SIMRenewalPlanRes;
  }

  saveMsadetail(tid, merchant_id, order_id, currency, amount, sucessurl, language, name, address, city, state, zip, country, tel, email, param1, param2, param3, param4, param5) {
    const fd = new FormData();
    fd.append('tid', tid);
    fd.append('merchant_id', merchant_id);
    fd.append('order_id', order_id);
    fd.append('currency', currency);
    fd.append('amount', "100");
    fd.append('redirect_url', sucessurl);
    fd.append('cancel_url', sucessurl);
    fd.append('language', language);
    fd.append('billing_name', name);
    fd.append('billing_address', address);
    fd.append('billing_city', city);
    fd.append('billing_state', state);
    fd.append('billing_zip', zip);
    fd.append('billing_country', country);
    fd.append('billing_tel', tel);
    fd.append('billing_email', email);
    fd.append('Merchant Param1', param1);
    fd.append('Merchant Param2', param2);
    fd.append('Merchant Param3', param3);
    fd.append('Merchant Param4', param4);
    fd.append('Merchant Param5', param5);
    return fd;
  }

  ValidateSIMCardActiveationStatus(SIMNo: string, InputSIMNo: any) {

    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
    this.ApplicationServiceService.CheckSIMCardActivationStatus(SIMNo, this.RegistrationID).subscribe((result: CCPayRequest) => {
      if (result != null) {
        if (result.SIMNo === InputSIMNo) {
          this.IsSIMAvtivated = true;
          alert('Provided SIM Card is Already Activated.! Please check the SIM No / contact to customer care support no.!');
          return;
        }
      }
    });
  }


  GetlatestMessage() {
    this.LoaderserviceService.currentMessage.subscribe((result: any) => {
      this.isLoading = result
    });

  }
}