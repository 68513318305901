<footer class="bd-footer bg-chatr border-top mt-auto chatr-font1">
  <div class="container">
    <nav class="navbar navbar-expand-md">
      <div class="container-fluid">
        <div class="navbar-collapse">
          <ul class="navbar-nav justify-content-between w-100">
            <li class="nav-item">
              <a class="nav-link" routerLink="/SIMCardRegistration">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/terms-conditions">Terms & Conditions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/RefundPolicy">Return & Refund Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</footer>