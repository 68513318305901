<header class="fixed-top border-bottom text-white text-center bg-white">
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-light py-0">
      <div class="container-fluid">
        <a class="navbar-brand canada-logo" href="/">
          <h2 class="text-logoText mb-0 f-italic fw-6"><a href="/"><img class="logo" src="../../assets/AppImages/logo.png"></a></h2>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse border-top border-top-md-0 f-italic" id="navbarSupportedContent">
           <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link fw-6 active" aria-current="page" href="#"> <img
                  src="../../assets/AppImages/cell-phone.svg" alt="Uniconnect" height="30" width="30"> 9:30 AM to 6:00
                PM Support</a>
            </li>

          </ul> 
          <div class="rightnav ms-auto">
            <div class="contactlinks d-inline-block text-md-start">
              <a href="tel:+918976012540" class="nav-item">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/phone-call.svg" alt="Uniconnect" height="30" width="30">
                <!-- +91 844 8449403 -->
                +91 897 601 2540
              </a>
              <a class="nav-item"
                href="https://api.whatsapp.com/send?phone=+918097735336&text=Lets%20talk%20to%20CanadaSIMregister!"
                target="_blank">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/whatsapp.svg" alt="Uniconnect" height="32" width="32">
                <!-- +91 996 7147000 -->
                +91 897 601 2540
              </a>
              <a class="nav-item" href="mailo:info@mycanadasim.com"><img height="30" width="30"
                  src="../../assets/AppImages/email.svg"> info@mycanadasim.com</a>
            </div>

          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
<div class="container-fluid bg-chatr chatr-header text-white text-center py-3 h1 ">
  myCanadaSIM
</div> 
