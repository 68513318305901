
export class PaymentModelRequest {
    tid: string
    merchant_id: string;
    order_id: string;
    currency: string = "INR";
    amount: number;
    redirect_url: string;
    cancel_url: string;
    language: string = "en";
    billing_name: string;
    billing_address: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    billing_tel: string;
    billing_email: string;
    Merchant_Param1: string;
    Merchant_Param2: string;
    Merchant_Param3: string;
    Merchant_Param4: string;
    Merchant_Param5: string;
}