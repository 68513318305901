import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refunpolicy',
  templateUrl: './refunpolicy.component.html',
  styleUrls: ['./refunpolicy.component.scss']
})
export class RefunpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
