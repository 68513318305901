import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderserviceService {

  constructor() { }
  private messageSource = new BehaviorSubject(false);
  currentMessage = this.messageSource.asObservable();
  show() {
    this.messageSource.next(true)
  }
  hide() {
    this.messageSource.next(false)
  }

  changeMessage() {
    this.messageSource.next(true)
  }
}
