export class SIMRegistrationSec {
    SImNumber: string
    PlanID: number
    SIMRegistrationID: string
    id: number
    DateofTravel?: Date
    DateofTravelTentative: string
    EmailID: string
}


export class SIMRegistrationChatr {
    SImNumber: string
    PlanID: number
    SIMRegistrationID: string
    id: number
    DateofTravel?: Date
    DateofTravelTentative: string
    EmailID: string
    address: string
    PostalCode: string
    City: string
    State: string
    FirstName: string
    LastName: string
    ContactNo: string
    ReceivedCity: string
    SaleId?:number
}
