<div class="container">
    <form #form ngNoForm id="nonseamless" method="post" name="redirect"
        action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
        <input type="hidden" id="encRequest" name="encRequest" value="{{encRequestRes}}">
        <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
    </form>

    <form #sf="ngForm" id="sf" novalidate>

    

        <div class="row">
            <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-2 border bg-white">
                <!-- <h4 class="secondheader mb-4">Enter your SIM Number and Choose Plan Details<sup class="text-danger"> *</sup> </h4> -->
                <h4 class="secondheader mb-4">Enter your SIM Number and Choose Plan Details<sup class="text-danger">
                        *</sup> </h4>
                <p class="secondheader mb-2">(Starts with 8 and contains 19 digits) </p>
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="SImNumber" id="SImNumber" placeholder="SIM No"
                                [(ngModel)]="SIMRegistrationSecModel.SImNumber" required #SImNumber="ngModel" ngModel
                                (ngModelChange)="ValidatetheSIMCard($event)">
                            <button class="btn btn-chatr" type="button" id="button-addon2"
                                (click)="ValidatetheSIMCard(SIMRegistrationSecModel.SImNumber)">Go</button>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <img class="img-fluid" src="../../assets/AppImages/sim-large.png">
                    </div>
                    <div *ngIf="!SImNumber?.valid && (SImNumber?.dirty || SImNumber?.touched)">
                        SIM number required
                    </div>
                    <div class="alert alert-danger" *ngIf="SIMCardStatus">
                        <!-- We have already received following sim card {{SIMRegistrationResponseModel.SIMCardNumberICCID}}
                        registration
                        request for the against of {{SIMRegistrationResponseModel.Indianmobilenumber}}. Please try with
                        another mobile number. -->
                        Please provide the valid sim card!
                        <br>
                        <button type="button" class="btn btn-chatr rounded-bottom" (click)="NewSaleEntry()">
                            Back to home</button>
                    </div>
                </div>

                <div id="DisplayPlanDetails" *ngIf="DisplayPlanMessage">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        Post paid plan of $40 + taxes, no payment or credit card details is required as of now.
                    </p>
                    <p>
                        This will be payable only after 20 days usage.
                    </p>
                    <ul>
                        <li>10GB on 4G LTE</li>
                        <li>Unlimited call in Canada</li>
                        <li>Unlimited text in Canada</li>
                        <li>$50 setup service fee waived</li>
                    </ul>
                    <!-- <p class="sc-color fs-13">
                        This is a special price plan for new visa applicants not available on Fido Website and has huge
                        saving in cost.
                    </p> -->
                    <div>
                        <h3>
                            <b> This is a special discounted price plan provides and offers huge saving in cost as
                                compared <br>to the usual plans offered by Fido</b>
                        </h3>
                    </div>

                    <div>
                        <h3>
                            <b> Postpaid invoice will be generated by Fido (Rogers network) and will be payable after 20
                                days</b>

                        </h3>
                    </div>

                </div>

                <div id="DisplayPlanDetailsKoodoo" *ngIf="DisplayPlanMessageKoodoo">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        This is a Prepaid plan of $45 from Koodoo (Telus). No payment is required and the plan is
                        complimentary for
                        first month. The SIM will be activated the moment you land in Canada and insert in mobile. For
                        the mobile
                        number please call another mobile and make note of it. The automated SMS takes a few hours to be
                        received. The plan includes:
                    </p>
                    <ul>
                        <li>5GB internet at 4G speed</li>
                        <li>Unlimited Canada wide calling</li>
                        <li>100 minutes to India</li>
                    </ul>
                    <!-- <p class="sc-color fs-13">
                        This is a special price plan for new visa applicants not available on Fido Website and has huge
                        saving in cost.
                    </p> -->
                    <div>
                        <h3>
                            <b> The plan is complimentary for first month and the same can be renewed on the Koodoo
                                website or through
                                any retailer</b>
                        </h3>
                    </div>
                </div>

                <div id="DisplayPlanDetailsChatr" *ngIf="DisplayPlanMessageChatr">
                    <div class="row">
                        <h4 class="secondheader sc-color my-3 text-start">Plan Details:</h4>
                    </div>
                    <p>
                        chatr is the leading prepaid brand in Canda. <br> Special offer for ‘First-time Canada
                        Visa applicants qualify for a special offer from chatr!’ <b> <i> Pay for one month and get second month free* </i>
                        
                        </b>
                        
                        <!-- (bonus credit will be given in your account equal to first
                        month plan by the 10th of the following month which can be used for future months renewal.
                        Hence, for plans expiring in the first 10 days of the month, the credit will need to be used for
                        3rd month renewal) -->

                        (bonus credit will be given in your account equal to first month plan by the 7th of the following month which can be used for future months renewal. Hence, for plans expiring from 24th to 7th of the following month, the credit will need to be used for 3rd month renewal)


                    </p>
                    <ul>
                        <li>$50 + taxes – 10 GB + unlimited Canada wide talk</li>
                        <li>$40 + taxes – 4.5 GB + unlimited Canada wide talk</li>
                        <li>$25 + taxes – 0.5 GB + unlimited Canada wide talk</li>
                        <li>$15 + taxes – starter pack of 100 Minutes Canada wide talk</li>

                    </ul>

                    <div>
                        <h3>
                            <!-- <b> The plan is complimentary for first month and the same can be renewed on the Koodoo
                                website or through
                                any retailer</b> -->
                        </h3>
                    </div>
                </div>
                <div id="mandatoryfield" *ngIf="DisplayMandatoryDiv">
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-2">
                            <label for="date" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>
                        </div>

                        <div class="col-md-4">
                            <div class="input-group">
                                <input placeholder="Date of Travel" matInput [matDatepicker]="pickerdot"
                                    id="DateofTravel" name="DateofTravel" class="form-control"
                                    [(ngModel)]="SIMRegistrationSecModel.DateofTravel" #Traveldate="ngModel"
                                    #DateofTravel="ngModel" required [min]="Startdate" [ngClass]="{
                        'is-invalid': (DateofTravel?.invalid && IsSubmitted),
                        'valid': !DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)
                   }">
                                <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                                <mat-datepicker #pickerdot></mat-datepicker>
                            </div>
                            <div *ngIf="!DateofTravel?.valid && (DateofTravel?.dirty || DateofTravel?.touched)">
                                Arrival Date
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label for="tdate" class="col-form-label textbold">Arrival Date<sup
                                    class="text-danger">*</sup></label>

                        </div>
                        <div class="col-md-4">
                            <select class="form-select" aria-label="Default select example"
                                [(ngModel)]="SIMRegistrationSecModel.DateofTravelTentative" name="DateofTravelTentative"
                                id="DateofTravelTentative" #DateofTravelTentative="ngModel" [ngClass]="{
                              'is-invalid': (DateofTravelTentative?.invalid && IsSubmitted),
                              'valid': !DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)
                         }">
                                <option selected>Confirmed</option>
                                <option>Tentative</option>
                            </select>
                            <div
                                *ngIf="!DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)">
                                Arrival Date
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-2">
                            <label for="email" class="col-form-label textbold">Email ID<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input type="email" class="form-control" id="EmailID" aria-label="email"
                                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                [(ngModel)]="SIMRegistrationSecModel.EmailID" required name="EmailID" #EmailID="ngModel"
                                [ngClass]="{
                              'is-invalid': (EmailID?.invalid && IsSubmitted),
                              'valid': !EmailID?.valid && (EmailID?.dirty || EmailID?.touched)
                         }">
                            <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
                                Email ID
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="email" class="col-form-label textbold">Address<sup
                                    class="text-danger">*</sup></label>
                        </div>

                        <div class="col-md-4">
                            <textarea type="text" class="form-control" id="address" aria-label="address"
                                [(ngModel)]="SIMRegistrationSecModel.address" required name="address" #address="ngModel"
                                [ngClass]="{
                              'is-invalid': (address?.invalid && IsSubmitted),
                              'valid': !address?.valid && (address?.dirty || address?.touched)
                         }"></textarea>
                            <div *ngIf="!address?.valid && (address?.dirty || address?.touched)">
                                Address
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <div class="col-md-2">
                            <label for="email" class="col-form-label textbold">City<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" id="City" aria-label="City"
                                [(ngModel)]="SIMRegistrationSecModel.City" required name="City" #City="ngModel"
                                [ngClass]="{
                              'is-invalid': (City?.invalid && IsSubmitted),
                              'valid': !City?.valid && (City?.dirty || City?.touched)
                         }">
                            <div *ngIf="!City?.valid && (City?.dirty || City?.touched)">
                                Address
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label for="email" class="col-form-label textbold">Postal Code<sup
                                    class="text-danger">*</sup></label>
                        </div>

                        <div class="col-md-4">
                            <input type="text" class="form-control" id="PostalCode" aria-label="PostalCode"
                                [(ngModel)]="SIMRegistrationSecModel.PostalCode" required name="PostalCode"
                                #PostalCode="ngModel" [ngClass]="{
                              'is-invalid': (PostalCode?.invalid && IsSubmitted),
                              'valid': !PostalCode?.valid && (PostalCode?.dirty || PostalCode?.touched)
                         }">
                            <div *ngIf="!PostalCode?.valid && (PostalCode?.dirty || PostalCode?.touched)">
                                Postal Code
                            </div>
                        </div>

                    </div>

                    <div class="row mb-4 align-items-center">
                        <div class="col-md-2">
                            <label for="email" class="col-form-label textbold">State<sup
                                    class="text-danger">*</sup></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" id="State" aria-label="State"
                                [(ngModel)]="SIMRegistrationSecModel.State" required name="State" #State="ngModel"
                                [ngClass]="{
                              'is-invalid': (State?.invalid && IsSubmitted),
                              'valid': !State?.valid && (State?.dirty || State?.touched)
                         }">
                            <div *ngIf="!State?.valid && (State?.dirty || State?.touched)">
                                State
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4 align-items-center" *ngIf="DisplayMandatoryDiv">
                        <div class="col-2">
                            <label class="col-form-label textbold">Plan Option<sup class="text-danger">*</sup></label>
                        </div>
                        <div class="col-5">
                            <select class="form-select" aria-label="Default select example"
                                [(ngModel)]="SIMRegistrationSecModel.PlanID" name="PlanID" id="PlanID" #PlanID="ngModel"
                                required [ngClass]="{
                                    'is-invalid': PlanID?.invalid,
                                    'valid': !PlanID?.valid && (PlanID?.dirty || PlanID?.touched)
                               }">
                                <option *ngFor="let RPlanName of SIMRenewalPlanResponse" [ngValue]="RPlanName.id">
                                    {{RPlanName.PlanName}}
                                </option>
                            </select>
                            <div *ngIf="!PlanID?.valid && (PlanID?.dirty || PlanID?.touched)">
                                Required
                            </div>
                        </div>
                        <div class="col-3">
                            <button type="button" class="btn btn-chatr rounded-bottom"
                                (click)="checkout(SIMRegistrationSecModel)" [disabled]="true">
                                Purchase Now</button>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        &nbsp;
                    </div>
                    <!-- <div class="row mb-4 align-items-center">
                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-danger" (click)="OnBackButton()"> &lt;
                                Previous</button>
                            &nbsp; &nbsp;
                            <button type="button" class="btn btn-danger" (click)="OnSubmit(SIMRegistrationSecModel)"
                                [disabled]="sf.invalid">Submit
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </form>

    <div class="container-fluid py-xxl-5 ">
        <div class="row">
            <div *ngIf="isLoading" class="overlay">
                <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
                </mat-progress-spinner>
            </div>

            <div class="col-md-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>


</div>