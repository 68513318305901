import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PlanLineItem, SIMPlanDetail } from '../Models/simplan-detail';
import { SIMRegistration } from '../Models/simregistration';
import { SIMRegistrationFinal } from '../Models/simregistration-final';
import { SIMRegistrationSec } from '../Models/simregistration-sec';
import { SIMRegistrationResponse, UserFiles } from '../Models/SIMRegistrationResponse';
import { City } from '../Models/City';
import { Expectedperiod } from '../Models/Expectedperiod';
import { SIMValidateResponse } from '../Models/SIMValidateResponse';
import { SIMRegistrationChatr } from '../Models/simregistration-sec'
import { NetworkList, PlanDescription, PlanList, PlanResponse } from '../Models/PlanDetailResponse';

import { PaymentModelRequest } from '../Models/PaymentModelRequest';

@Injectable({
  providedIn: 'root'
})
export class ApplicationServiceService {
  public BaseUrl: string = "https://www.canadasimregister.com/APIWEBSERVICE/";
  //public BaseUrl: string = "http://localhost:61908/";
  //public BaseUrlcanada = "http://localhost:61908/";

  constructor(private http: HttpClient) { }

  GetBaseAPIURL() {
    return "https://www.canadasimregister.com/APIWEBSERVICE/";
  }

  // 
  // Add SIM Registration First Page
  AddSIMRegistrationFirstPage(SIMRegistration: SIMRegistration): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/MyCanada';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistration, { headers: headers });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationSec(SIMRegistrationSec: SIMRegistrationSec, RegistrationID: string): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/Next';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationSec, { headers: headers });
  }

  // Add SIM Registration First Page
  AddSIMRegistrationFinal(SIMRegistrationFinal: SIMRegistrationFinal, RegistrationID: string): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/Final';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationFinal, { headers: headers });
  }

  //   Get SIM Plan
  GetSIMPlan(SIMNumber: string): Observable<SIMPlanDetail> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/SIMPlan/SIMPlan?SIMPlan=' + SIMNumber;
    return this.http.get<SIMPlanDetail>(URL);
  };

  // Get SIM Registration Details from SIM Registration ID

  GetSIMRegistrationDetail(RegistrationID: string): Observable<SIMRegistrationResponse> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/Registration/Get/' + RegistrationID;
    return this.http.get<SIMRegistrationResponse>(URL);
  };

  //   Get SIM Plan
  ValidateSIMCard(SIMNumber: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/SIMPlan/SIMPlan/Validate?SINICCD=' + SIMNumber;
    return this.http.get<any>(URL);
  };

  //   Get City List
  CityList(): Observable<City[]> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/SIMPlan/City';
    return this.http.get<City[]>(URL);
  };
  //
  //   Get GetExpectedperiods List
  GetExpectedperiods(): Observable<Expectedperiod[]> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/UserService/Expectedperiods';
    return this.http.get<Expectedperiod[]>(URL);
  };

  //  Get NEW SIM Validation Response
  SIMCardValidationNew(SIMNumber: string): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + 'api/SIMPlan/SIMPlan/ValidateNEW?SINICCD=' + SIMNumber;
    return this.http.get<SIMValidateResponse>(URL);
  };

  //  Get NEW SIM Validation Response
  SIMCardValidationByNetwork(SIMNumber: string, SIMNetwork: string): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + `api/SIMPlan/SIMPlan/ValidatebySource?SINICCD=${SIMNumber}&NetworkName=${SIMNetwork}`;
    return this.http.get<SIMValidateResponse>(URL);
  };

  //  Validate SoldOut SIM Card
  ValidateSouldoutSIMCard(SIMNumber: string): Observable<PlanResponse> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + `api/SIMPlan/GetSIMPlanDetailChatr?SIMPlan=${SIMNumber}`;
    return this.http.get<PlanResponse>(URL);
  };


  //  Get SIM product Renewal Plan
  GetSIMproductRenewalPlan(SIMNumber: string): Observable<SIMValidateResponse> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + `api/SIMPlan/SIMRenewalPlan?SIMPlan=${SIMNumber}`;
    return this.http.get<SIMValidateResponse>(URL);
  };


  // update Chatr details on payment button click
  // Add SIM Registration First Page
  SIMRegistrationChatrAdd(SIMRegistrationChatr: SIMRegistrationChatr): Observable<SIMRegistrationResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.BaseUrl + 'api/Registration/Add/updateSIMRegistrationChatr';
    return this.http.post<SIMRegistrationResponse>(URL, SIMRegistrationChatr, { headers: headers });
  }


  SIMRegistrationChatrAdd_New(SIMRegistrationChatr: SIMRegistrationChatr) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let promise = new Promise((resolve, reject) => {
      let URL = this.BaseUrl + 'api/Registration/Add/updateSIMRegistrationChatr';
      this.http.post(URL, SIMRegistrationChatr, { headers: headers })
        .toPromise()
        .then(
          res => { // Success
            //console.log(res);
            resolve(res);
          }
        );
    });
    return promise;
  }


  // Get Encrypted Data From API
  GetPaymentRequestEncyryptedData(FormData: PaymentModelRequest): Observable<PaymentModelRequest> {
    const headers = { 'content-type': 'application/json' }
    const header1 = { 'content-type': 'application/x-www-form-urlencoded' }
    const headers2 = { 'Content-Type': 'multipart/form-data' }
    let URL = this.BaseUrl + `api/ApplicationPaymentService/CanadaPaymentRequest`;
    // return this.http.get<any>(URL);
    return this.http.post<any>(URL, FormData, { headers: headers });
  }

  // Get Encrypted Data From API
  GetPaymentResponseEncyryptedData(ResponseData: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + `api/ApplicationPaymentService/CanadaPaymentResponse?encResp=${ResponseData}`;
    return this.http.get<any>(URL);
  }
  // Check SIM Card Activation Status
  CheckSIMCardActivationStatus(SIMNo: string, RegisterID: any): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    let URL = this.BaseUrl + `api/CCAPaymentService/GetSIMActivationStatus/${SIMNo}/${RegisterID}`;
    return this.http.get<any>(URL);
  }

}
