export class SIMRegistration {
    id: number
    SIMRegistrationID: string
    FirstName: string
    LastName: string
    Indianmobilenumber: string
    SourceName: string = "Chatr"
    CityReceived: string
    EmailID: string
    IsConsent: boolean = true;
}

