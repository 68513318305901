<div class="container">
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
    <div class="text-center">
        <p>
            Hi <b> {{SIMRegistrationResponseModel.FirstName}} </b>, Your SIM Card has been Registered
            <b> {{SIMRegistrationResponseModel.SIMCardNumberICCID}} </b><br>"A notification email will follow of the
            Phone number on your SIM card 48 hours prior to your travel date"
            <!-- <a href="/SIMCardRegistration">
                <h4>Click here Register other SIM Card</h4>
            </a> -->
        </p>
        <p>
           “You will soon be receiving a call, confirming all information required for your SIM activation.
        </p>
        <a class="button btn-chatr" href="https://www.canadasimregister.com/">Click here to register another SIM Card</a>
    
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    
    </div>
</div>