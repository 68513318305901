<h1>
    Your Transaction is a Success
</h1>
<div>
    <hr>
    <h2>Transaction details are below</h2>
    <p>Name : {{billing_name}}</p>
    <p>Payment_status : {{order_status}}</p>
    <p>bank_ref_no : {{bank_ref_no}}</p>
    <p>Amount : {{amount}}</p>
    <hr>
</div>
<div>
    <p>
        Save the EPIN below, it is also sent via Email on<b> {{ChatrPaymentResponse.email}}</b>
    </p>
    <div>
        <p> Selected plan <b> {{ChatrPaymentResponse.simPlanName}} </b>-EPIN:<br> </p>
        <div *ngFor="let product of ChatrPaymentResponse.atvResponse">
             <b> {{ product.voucherCode }}</b>
        </div>
    </div>
    <p>
        Setup your account on <a href="https://www.chatrwireless.com/">www.chatrwireless.com</a> on the day of travel to
        get phone number. The plan starts the day <br>the it is registered on website and EPIN applied.
        &nbsp; <br>
        <br>Please note: Indian credit cards are not accepted on <a
            href="https://www.chatrwireless.com/">www.chatrwireless.com</a>
    </p>
    <p>
        For any support on this please feel free to call on +91 844 844 9402
    </p>

    <p>
        “You will soon be receiving a call, confirming all information required for your SIM activation.
     </p>
    <p>
        <a href="https://www.mycanadasim.com/">Back to home page</a>
    </p>
</div>