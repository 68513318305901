import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CCPayRequest } from '../CCPaymentServices/CCPayRequest';
import { Observable } from 'rxjs';

import {ATVResponse, ChatrPaymentResponse} from '../PaymentResponse/ChatrPaymentResponse';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  PaymentbaseUrl = 'hhttps://www.canadasimregister.com/APIWEBSERVICE/';
   public APIbaseUrl: string = "https://www.canadasimregister.com/APIWEBSERVICE/";
  //public APIbaseUrl: string = "http://localhost:61908/";


  constructor(private http: HttpClient) { }

  encryptdata(request: any) {
    let url = `${this.PaymentbaseUrl}orders/encryptFormData`;
    let data = {
      request: request
    }
    return this.http.get(url, { params: data })
  }

  // Add Request Data into the DB
  PostPaymentRequestData(CCPayRequest: CCPayRequest): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.APIbaseUrl + `api/CCAPaymentService/PostCCAPaymentService`;
    return this.http.post<any>(URL, CCPayRequest, { headers: headers });
  }

  // Get Voucher PINS
  GetPaymentAndVoucher(SIMRegistration:any): Observable<ChatrPaymentResponse> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    let URL = this.APIbaseUrl + `api/ApplicationVoucherService/GenerateInvoice?SIMNo=${SIMRegistration}`;
    return this.http.get<ChatrPaymentResponse>(URL);
  }

}
