import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SIMRegistrationComponent } from './simregistration/simregistration.component';
import { CustomercareloginComponent } from './customercarelogin/customercarelogin.component';
import { SIMCardStatusComponent } from './simcard-status/simcard-status.component';
import { ViewPlanComponent } from './view-plan/view-plan.component';
import { SImCardRegistrationContComponent } from './sim-card-registration-cont/sim-card-registration-cont.component';
import { SImCardRegistrationFinalComponent } from './sim-card-registration-final/sim-card-registration-final.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import {SuccessComponent} from '../app/success/success.component';
import {FailedComponent} from '../app/failed/failed.component';

import {TernAndConditionComponent} from '../app/tern-and-condition/tern-and-condition.component';
import {PrivacyPolicyComponent} from '../app/privacy-policy/privacy-policy.component';
import {RefunpolicyComponent} from '../app/refunpolicy/refunpolicy.component'; 



const routes: Routes = [

  { path: 'SIMCardRegistration', component: SIMRegistrationComponent },
  { path: 'SIMCardRegistration/:RegistrationID', component: SIMRegistrationComponent },
  { path: '*/:RegistrationID', component: SIMRegistrationComponent },
  { path: '#', component: SIMRegistrationComponent },
  { path: '', component: SIMRegistrationComponent },
  { path: 'SIMCardRegistrationCont/:RegistrationID/Next', component: SImCardRegistrationContComponent },
  { path: 'SIMCardRegistrationFinal/:RegistrationID/Final', component: SImCardRegistrationFinalComponent },
  { path: 'Thankyou/:RegistrationID', component: ThankyouComponent },
  { path: 'PaymentSuccess', component: SuccessComponent },
  { path: 'PaymentFailed', component: FailedComponent },
  { path: 'RefundPolicy', component: RefunpolicyComponent },
  { path: 'terms-conditions', component: TernAndConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
