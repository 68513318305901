export class CCPayRequest {
    id?: number
    FirstName: string
    LastName: string
    Email: string
    ContactNo: string
    RequestFromCity: string
    address: string
    city: string
    pincode: string
    state: string
    SIMNo: string
    RegisterID: any
    SaleID?: Number
    OrderId: string
    PlanId?: number
    Amount: number
    PaymentRespose1: string
    PaymentRespose2: string
    PaymentRespose3: string
    PaymentRespose4: string
    PaymentRespose5: string
    TransactionDate?: Date
    PaymentDate?: Date
    TransactionID: string
    PaymentID: string
    CreatedDate?: Date
    UpdatedDate?: Date
    PaymentStatus: string
    SIMNetwork: string
    PlanRenewalID?: number
    RenewalPlanName: string
    IsEmailSent?: boolean = false
    EmailSendDate?: Date
    BillNo: string
    IsInvoiceGenerated?: boolean = false;
    InvoiceGenerated?: Date
}