export class ChatrPaymentResponse {
    email: string
    atvResponse: Array<ATVResponse>
    simPlanName: string
}

export class ATVResponse {
    atvCode: string
    voucherCode: string
    planName: string
    planID?: number
}