import { Injectable } from '@angular/core';
import {Buffer} from 'buffer/';
import * as crypto from "crypto-browserify";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class CCPaymentservice {
  private workingKey: string = 'A569F8718BB88700483D25F8CB745E32';
  private publicKey: "AVPN01BC29CE01NPEC";
  private enabled: boolean = true;

  constructor() { }
  encrypt(plaintext: string): string {
    if (!this.enabled)
      return plaintext;
    let buffer = new Buffer(plaintext);
    console.log(buffer)
    let encrypted = crypto.privateEncrypt(this.workingKey, buffer);
    return encrypted.toString('base64');
  }

  decrypt(cypher: string): string {
    if (!this.enabled)
      return cypher;

    let buffer = Buffer.from(cypher, 'base64');
    let plaintext = crypto.publicDecrypt(this.workingKey, buffer);

    return plaintext.toString('utf8')
  }



}
