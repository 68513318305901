export class SIMRegistrationResponse {
    id?: number
    SIMRegistrationID: any
    FirstName: string
    LastName: string
    EmailID: string
    Indianmobilenumber: string
    DateofTravel?: Date
    DateofTravelTentative: string
    SIMCardNumberICCID: string
    PlanID?: number
    Line1: string
    Line2: string
    City: string
    Zipcode: string
    Province: string
    DateofBirth?: Date
    StudyingUniversityName: string
    ProvinceState: string
    UCCID: string
    IMEINumber: string
    TravelDateReConfirm?: Date
    ConfirmAge?: number
    ExpiryDateofVIsaDocument?: Date
    Whichphoneusing: string
    PhoneModel: string
    FlightNumber: string
    IsActive?: boolean
    CreatedDate?: Date
    UpdatedDate?: Date
    UserFiles?: Array<UserFiles>
    Remark: string
    PermitExpiryDate?: Date
    CanadianVisaDocumentNumber: string
    PassportNumber: string
    acceptance? : boolean
    VisaStatus: string
    OtherVisaValue : string
    UsaPlanRenewalID?: number
    CityReceived: string
}

export class UserFiles {
    id?: number
    FileId: string
    FileType: string
    Blob?: ArrayBuffer[]
    IsActive?: boolean
    CreatedDate?: Date
    UpdatedDate?: Date
    SIMRegistrationID: number
    FileName: string
    SIMRegistrationGuid: string
}

